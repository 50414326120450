import { PageResult, Task, TaskParams } from '../../models';
import { CCApiService } from './api-service';
import { UIConfig } from './config-service';

const DEFAULT_OPTIONS = {
  page: 0,
  size: 20,
};

export class TaskApiService {
  static ApiService: CCApiService;
  static taskApi: string;

  static init(config: UIConfig) {
    this.ApiService = new CCApiService(config.authClientId, config.authIssuerUri);
    this.taskApi = `${config.apiBasePath}/tasks`;
  }

  /**
   * Get tasks with the metadata for pagination.
   * @param options Task fetch options object
   */
  static getTasks(options?: TaskParams): Promise<PageResult<Task>> {
    const opts = { ...DEFAULT_OPTIONS, ...options };
    const params = new URLSearchParams(opts);
    const url = params.toString() ? `${this.taskApi}?${params}` : this.taskApi;
    return this.ApiService.performFetch(url)
      .then(solutions => solutions)
      .catch(err => {
        console.error(err);
        return [];
      });
  }

  static getTask(id: string) {
    return this.ApiService.performFetch(`${this.taskApi}/${id}`).catch(err => {
      console.error(err);
      return {};
    });
  }
}
